import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import SoftwareArticles from 'components/Software/Home_Automation/IntroHomeAutomation';
import YoutubeVideoCard from 'components/Software/Home_Automation/YoutubeVideoCard';
import NavButtons from 'components/Software/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Camera Surveillance Software' dateChanged='2017-12-08' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use our free mobile apps for Android, iOS, Windows Phone or Blackberry. Or use the capabilities of our InstarVision 2 Surveillance Station.' image='/images/Search/P_SearchThumb_Products.png' twitter='/images/Search/P_SearchThumb_Products.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Smarthome/' locationFR='/fr/Software/Home_Automation/' crumbLabel="Automation" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "home-automation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#home-automation",
        "aria-label": "home automation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Home Automation`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <YoutubeVideoCard mdxType="YoutubeVideoCard" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Dive into the world of seamless smart home integration with the ultimate IP camera! Discover the best IP camera that transcends connectivity boundaries, whether through LAN, PoE, or WiFi – the possibilities are truly limitless.`}</p>
    <p>{`In this video, we unveil the perfect IP camera that effortlessly integrates with various platforms, ensuring your smart home security is second to none. From ONVIF and RTSP to MJPEG and JPEG, this camera supports it all, providing unparalleled flexibility for your surveillance needs.`}</p>
    <h3 {...{
      "id": "unrivaled-integration-options",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#unrivaled-integration-options",
        "aria-label": "unrivaled integration options permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Unrivaled Integration Options:`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Apple HomeKit & HomeKit Secure Video (HKSV)`}</strong>{`: Elevate your Apple ecosystem with secure and efficient video monitoring.`}</li>
      <li parentName="ul"><strong parentName="li">{`Amazon Alexa Show`}</strong>{`: Control and view your camera feeds with simple voice commands.`}</li>
      <li parentName="ul"><strong parentName="li">{`IFTTT Integration`}</strong>{`: Create custom automation scenarios to enhance your smart home experience.`}</li>
      <li parentName="ul"><strong parentName="li">{`MQTT Version 5`}</strong>{`: Harness the power of efficient messaging for real-time communication.`}</li>
      <li parentName="ul"><strong parentName="li">{`CGI Interface`}</strong>{`: Unlock advanced functionalities with Common Gateway Interface commands.`}</li>
      <li parentName="ul"><strong parentName="li">{`Alarm Server (HTTP(S) Requests)`}</strong>{`: Enhance security with automated alerts triggered by customizable HTTP(S) requests.`}</li>
      <li parentName="ul"><strong parentName="li">{`Google Home Compatibility`}</strong>{`: Seamlessly integrate your IP camera with Google Home for comprehensive control.`}</li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <SoftwareArticles mdxType="SoftwareArticles" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      